// 操作dom
export default {
  data(){
    return {
      chatHeight: 0,
      pageHeight: 0,
    }
  },
  methods:{
    async initHeight() {
      const systemInfo = this.$tools.getSystemInfo();
      let ids = ["header", "main", "banner", "footer"];
      let countHeight = 0;
      let pageHeight = await this.getSelctorHeight("live");
      this.pageHeight = pageHeight;
      let targetHeight = systemInfo.screenHeight || pageHeight;
      for (let id of ids) {
        countHeight += await this.getSelctorHeight(id);
      }
      this.chatHeight = pageHeight - countHeight;
      // this.chatHeight = this.chatHeight
    },
    

    async getScale(height) {
      const systemInfo = this.$tools.getSystemInfo();
      let targetHeight = systemInfo.screenHeight || this.pageHeight;
      return height / (this.pageHeight / targetHeight);
    },
    async getSelctorHeight(id) {
      return document.getElementById(`${id}`).clientHeight
    },
    initScrollTop() {
      this.$nextTick(()=>{
        document.getElementById("content").scrollTo(0,0)
      })
    },
    initScrollBottom() {
      this.$nextTick(()=>{
        document.getElementById("content").scrollTo(0,this.$refs.contentItem.clientHeight)
      })
    },
  }
}
