// 流事件管理
// const plugin = requirePlugin("liveRoomPlugin");

const common = {
  version: 2, // 腾讯版本
  liveAppID: 1320573990, // 腾讯会议号APPID，登录腾讯会议，找到个人信息，就可以看到appid了
  orientation: "vertical", // 方向 vertical 水平  horizontal 垂直
  minCache: 1, // 最小缓冲区 1s
  maxCache: 3, // 最大缓冲区 3s
  muted: false, // 是否静音
  debug: false, // 是否开启debug
};

export default {
  data() {
    return {
      stream: {
        push: {
          ...common,
          pushUrl: "", // 推流链接
          waitingImage: "", // 离开的图片页面
          enableCamera: true, // 是否开启摄像头
          autopush: false, // 是否自动推送
          mirror: true, // 是否镜像翻转
          beauty: 4, // 美颜指数 0-9
          whiteness: 4, // 美白指数 0-9
          backgroundMute: true, // 进入后台时候是否静音
          autoFocus: true, // 是否自动聚焦
          aspect: "9:16", // 推流比例
          mode: "SD",
          minBitrate: 200, // 最小码率
          maxBitrate: 1000, // 最大码率
          zoom: false, // 自动调整焦距
          show:false, // 是否显示直播组件
          devicePosition: "front", //初始化摄像头为前置还是后置，只能初始化的时候设置，动态调整用switchCamera
        },
        play: {
          ...common,
          objectFit: "contain",
          playUrl: "",
          autoplay: true,
          mode: "live", // 清晰度
        },
      },
      
      demoUrl:"https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/statics/demo.mp4",
      playInstance: null,
      pushInstance: null,
      isStreamClick:false,
      listenMusicing:false, // 是否在监听音乐
      streamCodes:[
        {code:1101, msg:"推流：网络状况不佳：上行带宽太小，上传数据受阻"},
        {code:3001, msg:"推流：RTMP DNS解析失败"},
        {code:5001, msg:"系统电话打断或者微信音视频电话打断"},
        {code:10001, msg:"用户禁止使用摄像头"},
        {code:10002, msg:"用户禁止使用录音"},
        {code:-1301, msg:"推流：打开摄像头失败"},
        {code:-1302, msg:"推流：打开麦克风失败"},
        {code:-1303, msg:"推流：视频编码失败"},
        {code:-1304, msg:"推流：音频编码失败"},
        {code:-1305, msg:"推流：不支持的视频分辨率"},
        {code:-1306, msg:"推流：不支持的音频采样率"},
        {code:-1307, msg:"推流：网络断连，且经多次重连抢救无效，更多重试请自行重,启推流"},
        {code:-1308, msg:"推流：开始录屏失败，可能是被用户拒绝"},
        {code:-1309, msg:"推流：录屏失败，不支持的Android系统版本，需要5.0以上的系统"},
        {code:-1310, msg:"推流：录屏被其他应用打断了"},
        {code:-1311, msg:"推流：Android Mic打开成功，但是录不到音频数据"},
        {code:-1319, msg:"自定义：iphone来电等导致通话中断"}
      ],
      isAllowPermission:false,
      isCanPlay:false,
    };
  },
  methods: {
    initPushStream(){
      if(this.meetingInfo.phase == 0) return;
      let key = this.meetingInfo.is_test ? "test_push_url" : "push_url"
      this.stream.push.pushUrl = this.meetingInfo.meet_info[key] || ""
    },
    initPlayStream(){
      if(this.meetingInfo.phase == 0) return;
      let key = this.meetingInfo.is_test ? "test_play_url" : "play_url"
      this.stream.play.playUrl = ( this.meetingInfo.meet_info[key] || "").replace(/https?/,'https')
    },
    // 创建播放实例
    createPlayInstance() {
      if(this.playInstance) return;
      // this.playInstance = plugin.instance.getLiveRoomInstance();
    },
    // // 创建推流实例
    // createPushInstance() {
    //   if(this.pushInstance) return;
    //   this.pushInstance = plugin.instance.getLiveRoomPushInstance();
    // },
    // 监听网络事件
    onNetStatusEvent() {},
    // 监听push事件
    onPushEvent() {
      this.meetingInfo.isPushing = true;
    },
    toggleStream() {
      this.meetingInfo.isPushing ? this.stopStream() : this.pushStream();
    },
    // 开始推流
    startPlayStream() {
      if(this.isStreamClick || this.meetingInfo.isPlaying || !this.closeWelcomeTip || this.isCanPlay) return;
      console.log("startPlayStream")
      this.initPlayStream()
      this.$nextTick(()=>{
        this.$refs.h5Player.switch(this.stream.play.playUrl)
      })
      this.isStreamClick = true
      setTimeout(()=>{   this.isStreamClick = false},100)
    },
    
    loaded(){
      if(!this.isStreanPushing || this.meetingInfo.isPlaying  || this.isCanPlay) return;
      this.isCanPlay = true;
      // if(this.popupKeys['play']){
      //   this.isAllowPermission = true;
      //   this.meetingInfo.isPlaying = true;
      //   this.isCanPlay=false;
      //   this.$refs.h5Player.setMuted(false);
      //   this.$refs.h5Player.play();
      // }else{
      //   this.popupKeys["play"] = ""
      //   this.initPlayTip()
      // }
      
      this.popupKeys["play"] = ""
      this.initPlayTip()
      // this.meetingInfo.isPlaying = true;
    },
    startPlay(){
      this.playInstance && this.playInstance.start()
    },
    // 开始推流
    stopPlayStream() {
      if(this.isStreamClick || !this.meetingInfo.isPlaying) return;
      // if(this.isAllowPermission) this.isAllowPermission = false
      console.log("调用stopPlayStream")
      this.isStreamClick = true
      this.closeStreamPopup()
      this.stream.play.playUrl = ""
      this.$refs.h5Player.switch(this.demoUrl)
      this.$refs.h5Player.setMuted(true)
      this.meetingInfo.isPlaying = false;
      setTimeout(()=>{   this.isStreamClick = false;},100)
      this.playInstance && this.playInstance.stop()
    },
    // 开始推流
    pushStream() {
      if(this.isStreamClick) return;
      this.isStreamClick = true
      this.stream.push.enableCamera = true
      this.showDownTime = 4;
      this.sendLog("startPushStream","开始推流")
      this.initPushStream()
      this.meetingInfo.isPushing = true;
      this.stream.push.show = true
      setTimeout(()=>{   
        this.createPushInstance()
        this.isStreamClick = false;
        this.pushInstance && this.pushInstance.start()
      },100)
    },
    // 结束推流
    stopStream(status=false) {
      if(this.isStreamClick) return;
      
      // wx.showLoading({
      //   title: '暂停中',
      //   mask: true
      // });
      this.sendLog("stopPlayStream","结束推流")
      this.isStreamClick = true
      this.stream.push.pushUrl = ""
      this.stream.push.enableCamera = false
      if(!status){
        this.initPauseTip()
      }
      this.meetingInfo.isPushing = false;
      setTimeout(()=>{   this.isStreamClick = false},100)
      this.pushInstance && this.pushInstance.stop()
      this.stream.push.show = false
      // setTimeout(()=>{   this.isStreamClick = false;
      //   wx.hideLoading();},100)
    },
    // 监听播流状态
    onPlayEvent(e){
      if(e.detail.code == -2301) {
          this.stopPlayStream();
      }
    },
    onPlayNetEvent(e){
    },
    /**
     * 监听网络事件
     * netQualityLevel	网络质量：0：未定义 1：最好 2：好 3：一般 4：差 5：很差 6：不可用
     * netStatus 0 好 1 一般 2 差
     */
    onNetStatus(e) {
      // try{
      //   let info = e.detail.detail.info || {}
      //   let level = info.netQualityLevel || 0;
      //   console.log(level)
      //   if([1,2].includes(level)){
      //     this.meetingInfo.netStatus = 0
      //   }else if ([0,3].includes(level)){
      //     this.meetingInfo.netStatus = 1
      //   }else{
      //     this.meetingInfo.netStatus = 2
      //   }
      // }catch(err){
      //   console.log(err)
      // }
    },
    // 监听语音被占用
    listenMusic(e){
      if(this.listenMusicing) return;
      this.listenMusicing = true;
      this.stopStream()
      setTimeout(()=>{
        this.listenMusicing = false;
      },500)
    },
    /**
     * 监听推流事件
     */
    onPushEvent(e) {
      console.log(e,e.detail)
      switch (e.detail.tag) {
        case "pushEvent": 
          if(this.streamCodes[e.detail.code]){
            this.sendLog(e.detail.tag,{
              code:e.detail.code,
              msg:this.streamCodes[e.detail.code]
            })
          }
          if(e.detail.code == 5001){
            this.stopStream()
          }
          break;
        case "error": 
          this.sendLog(e.detail.tag,{
            code:e.detail.code,
            msg:this.streamCodes[e.detail.code]
          })
          break;
      }
    }
  },
}
