<template>
  <div class="live-select-container">
    <div class="live-select-main">
      <div class="live-select-input">
        <input  placeholder="点击此处选择大家都关心的问题" ref="message" v-model="value" @input="changeValue" @focus="focusInput" @blur="blurInput" />
        <!-- <div class="live-select-mask" @click="showList"></div> -->
      </div>
      <div @click="toggleList"  class="live-select-icon">
        <el-icon>
        <ArrowUp  v-if="show" size="16"></ArrowUp>
        <ArrowDown v-else  size="16"></ArrowDown>
      </el-icon>
        </div>
      <div class="live-select-button global-color" @click="sendMessage">发送</div>
    </div>
    <div class="live-select-ul" v-show="show" >
      <div scroll-y class="live-select-scroll">
        <div class="live-select-li" v-for="(item,index) in getList()" @click="selectItem(item)" :key="item">{{index+1}}:{{ item }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElIcon } from 'element-plus'
import { ArrowDown,ArrowUp } from '@element-plus/icons'
export default  {
  name:"live-select",
  components:{
    ElIcon,
    ArrowDown,
    ArrowUp
  },
  props:{
    meetingInfo:{
      type:Object,
      default(){
        return {}
      },
    }
  },
  data:()=>{
    return {
      list:[],
      show:false,
      value:""
    }
  },
  methods:{
    getList(){
      return this.meetingInfo?.word_commcon || []
    },
    selectItem(item){
      this.value = item
      this.closeList()
      this.$refs.message && this.$refs.message.blur()
    },
    closeList(){
      this.show = false
    },
    toggleList(){
      this.show = !this.show
    },
    showList(){
      this.show = true
    },
    changeValue(){
      this.value = this.value.replace(/\s/g,"")
    },
    focusInput(){
      this.showList()
    },
    blurInput(){
      // this.closeList()
    },
    async sendMessage(){
      this.closeList()
      let content = this.value.trim();
      if(content == "") return;
      this.value = ""
      let data = await this.$tools.getChatContent(content,0)
      this.$emit("sendMessage",data)
    },
  }
}
</script>
<style>
custom-select{
  width:100%;
}
.live-select-container{
  height:calc(30px + 2px);
  width:100%;
  position: relative;
}
.live-select-icon{
  padding:0 15px;
  color:#000;
  display: flex;
  align-items: center;
}
.live-select-input{
  flex:1 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;;
}
.live-select-mask{
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:10;
}
.live-select-container input{
  border:none;
  padding:none;
  width:100%;
  font-size:16px;
  height:16px;
  padding:0 8px;
  color:#000;
  position: relative;
  z-index:0;
  line-height: 16px;
}
.live-select-container input::placeholder{
  font-size:16px;
}
.live-select-main{
  width:100%;
  height:100%;
  border-radius:75px;
  border:1px solid #ccc;
  background:#fff;
  display: flex;
  position: relative;
  z-index:1001;
}
.live-select-button{
  padding:0 15px;
  font-size:16px;
  border-left:1px solid #ccc;
  line-height: 30px;
}

.live-select-ul{
  position: absolute;
  bottom:0;
  padding-bottom:30px;
  background:#000;
  border:1px solid #ccc;
  z-index:1000;
  width:100%;
  border-radius:15px;
  overflow: scroll;
}
.live-select-scroll{
  height:300px;
  padding:15px 0;
  overflow: scroll;
}
.live-select-li{
  font-size:16px;
  padding:0 8px;
  margin-bottom:8px;
}
</style>
