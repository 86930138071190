// 操作获取数据类
export default {
  data(){
    return {
    }
  },
  methods:{
    getPrompt(){
      if(!this.meetingInfo.class_info?.content) return ""
      let doctorIndex = this.meetingInfo.doctor_index > 0 ? this.meetingInfo.doctor_index-1 : 0
      console.log(doctorIndex,2222222222222222222222222)
      return this.meetingInfo.class_info?.content?.[doctorIndex]?.prompt_content || ""
    },
    getLiveStatusIcon(){
      let name = this.meetingInfo.isPushing ? "play.gif" : "pause.png"
      return require(`@/assets/images/live/${name}`)
    },
    getLiveStatusName(){
      let name = this.meetingInfo.isPushing ? "暂停" : "开始"
      return `点击${name}直播`
    },
    getMinute(second){
      return Math.ceil(second/60)
    },
  }
}
