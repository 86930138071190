<template>

<div class="v2-live-header" >
      <div class="v2-live-info-main">
        <div class="v2-live-info-main-block">
          <div class="v2-live-info-block">
            <div class="v2-live-info-item">
              <span class="v2-live-info-key">主持专家：</span>
              <span class="v2-live-info-value v2-live-info-green">{{meetingInfo.meet_info.doctor_name}} 在线</span>
            </div>
          </div>
          <div class="v2-live-info-item">
            <span class="v2-live-info-key">在线人数：</span>
            <span class="v2-live-info-value">{{ meetingInfo.count }}</span>
          </div>
          <div class="v2-live-info-block" v-if="meetingInfo.role == 1">
            <div class="v2-live-info-item">
              <span class="v2-live-info-key">会议已进行：</span>
              <span class="v2-live-info-value">{{ getShowTime() }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="v2-live-push-main">
        <slot></slot>
      </div>
    </div>
</template>
<script>
export default {
  name:"live-header",
  data(){
    return {
      netColors:['green','orange','red']
    }
  },
  components:{
  },
  props:{
    meetingInfo:{
      type:Object,
      default(){
        return {}
      },
    }
  },
  methods:{
    getNetStatus(){
      return this.netColors[this.meetingInfo.netStatus] || "red"
    },
    getShowTime(){
      let time = this.meetingInfo.local_tm
      return this.$tools.getShowTime(time * 1000,"hh:mm:ss")
    },
  }
}
</script>
<style>

.v2-live-header{
  display: flex;
  justify-content: space-between;
  height:80px;
}
.v2-live-info-main-block{
  width:100%;
}
.v2-live-info-main{
  padding:15px;
  display: flex;
  flex:1 0 0;
  align-items: center;
}
.v2-live-info-item{
  line-height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.v2-live-info-block:first-child .v2-live-info-item:nth-child(2){
   margin-left:30px;
}
.v2-live-info-key{
  font-size:15px;
  font-weight: 700;
}
.v2-live-info-value{
  font-size:15px;
  display: flex;
  align-items: center;
  /* background:cyan; */
}
.v2-live-info-value-icon{
  line-height: 20px;
  display: flex;
  align-items: center;
}
.v2-live-info-block{
  display: flex;
  /* justify-content: space-between; */
}
.v2-live-push-main{
  /* 竖屏 1080*1920 */
  /* height:22vw;
  width:12.375vw; */
  /* 横屏 1920*1080 */
  /* height:22vw;
  width:39.11vw; */
  /* 特殊 */
  /* height:22vw;
  width:30vw; */
  /* 正方形 */
  height:80px;
  width:80px;
}
.v2-live-info-green{
  color:#0d883c;
}
</style>

