<template>
  <div class="v2-banner-container">
    <div
      class="global-center v2-banner-block"
      v-if="isShowTipsText()"
      @click="changeBanner('text')"
      :class="`${meetingInfo.tab == 'text' ? 'v2-banner-select' : ''} `"
    >
      <div
        class="v2-banner-text global-center"
        :class="`${meetingInfo.tab == 'text' ? 'v2-banner-active' : ''} `"
        >{{ banner[0].text }}</div
      >
    </div>
    <div class="v2-banner-block-separator global-center" v-if="isShowTipsText()">
      <div class="v2-banner-block-separator-item"></div>
    </div>
    <div
      class="global-center v2-banner-block"
      @click="changeBanner('chat')"
      :class="`${meetingInfo.tab == 'chat' ? 'v2-banner-select' : ''} `"
    >
      <div
        class="v2-banner-text global-center"
        :class="`${meetingInfo.tab == 'chat' ? 'v2-banner-active' : ''} `"
        >{{ banner[1].text }}</div
      >
    </div>
    <div class="v2-banner-block-separator global-center">
      <div class="v2-banner-block-separator-item"></div>
    </div>
    <div
      class="global-center v2-banner-block"
      @click="changeBanner('info')"
      :class="`${meetingInfo.tab == 'info' ? 'v2-banner-select' : ''} `"
    >
      <div
        class="v2-banner-text global-center"
        :class="`${meetingInfo.tab == 'info' ? 'v2-banner-active' : ''} `"
        >{{ banner[2].text }}</div
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "banner",
  props: {
    meetingInfo: {
      type: Object,
      default: () => {
        return {
          message: [],
        };
      },
    },
    isGuest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      banner: [
        {
          text: "解说词",
          key: "text",
        },
        {
          text: "提问区",
          key: "chat",
        },
        {
          text: "会议介绍",
          key: "info",
        },
      ],
    };
  },
  methods: {
    isShowTipsText(){
      // return !this.isGuest
      return !this.isGuest && this.meetingInfo.doctor_index <= (this.meetingInfo?.class_info?.content?.length || 0)
    },
    changeBanner(key) {
      this.$emit("changeBanner", key);
    },
  },
};
</script>
<style scoped lang="scss">
.v2-banner-container {
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  font-weight: 700;
  width: 100%;
  // border-top:2px solid #ccc;
}

.v2-banner-block {
  flex: 1 0 0;
  height: 100%;
  // border-top: 1px solid #ebebeb;
  // border-bottom: 1px solid #ebebeb;
  position: relative;
}
.v2-banner-text {
  height: 100%;
  width: 50%;
  position: relative;
}
.v2-banner-active {
}
.v2-banner-active::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1.875px;
  position: absolute;
  bottom: 2.775px;
  right: 0;
  z-index: 400;
  background: #fff;
  border-radius: 1.875px;
}
.v2-banner-block-separator {
  // flex: getSize(22.5) 0 0;
  flex: 1px 0 0;
  height: 100%;
  // background:#000;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.v2-banner-block-separator-item {
  width: 1px;
  height: 15px;
  background: #ccc;
}
.v2-banner-select {
  background: #000;
  color: #fff;
}
</style>
