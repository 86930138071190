<template>
  <div class="meeting_tip_container global-center"  :style="popupStyle">
    <div class="meeting_tip_main">
      <div class="meeting_tip_title global-center">
        <div class="meeting_tip_bg global-center">
          {{ config.title }}
        </div>
      </div>
      <div class="meeting_tip_content global-center">
        <slot></slot>
      </div>
      <div class="meeting_tip_button_group global-center" v-if="config.button.length >0">
        <div
          class="meeting_tip_button_cancel meeting_tip_button_block global-center"
          v-for="(item, index) in config.button"
          :key="index"
        >
          <custom-button size="mini" @click="doSelect(index)" :type="config.type[index]" styles="padding:0 30px;" round>{{ item }}</custom-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "meeting_tip",
  props: {
    cfg: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    CustomButton,
  },
  data() {
    return {
      config: {
        title: "添加常用语",
        button: ["取消", "确认"],
        type:["primary",'primary']
      },
      popupStyle:""
    };
  },
  created() {
    this.config = {
      ...this.config,
      ...this.cfg,
    };
    this.$nextTick(() => {
      let clientWidth = window.innerWidth;
      let popupWidth = document.body.clientWidth;
      this.popupStyle = `width:calc(${popupWidth}px);left:${
        (clientWidth - popupWidth) / 2
      }px`;
    });
  },
  methods: {
    doSelect(index) {
      this.$emit("doSelect", index);
    },
  },
};
</script>
<style lang="scss">
.meeting_tip_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 30, 30, 0.2);
  z-index: 8999;
  color:#000;
}
.meeting_tip_main {
  background: #fff;
  border-radius: 3.75px;
  min-width: 80%;
  max-width: 90%;
  padding-bottom: 15px;
}
.meeting_tip_title {
  height: 30px;
  font-size: 13.5px;
}
.meeting_tip_bg {
  height: 100%;
  width: 120px;
  background: url("../../../../assets/images/live/tip.jpg");
  background-size: 100% 100%;
  color: #fff;
}
.meeting_tip_content {
  height: auto;
  min-height: 75px;
  display: flex;
  align-items: center;
  width: 100%;
  font-size:15px;
}
.meeting_tip_button_group {
  height: 30px;
  display: flex;
  font-size: 13.5px;
}
.meeting_tip_button_block {
  height: 100%;
  width: 100%;
  flex: 1 0 0;
}
.meeting_tip_button_cancel {
}

.meeting_tip_button {
  margin-right: 15px;
}
</style>
