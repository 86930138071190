<template>
  <div class="live-info-guzhuanyi-container" :style="$tools.getImageBg('info.bg')">
    <div class="live-info-guzhuanyi-title_banner">
      <img :src="$tools.getImageContent('info.banner')" class="live-info-guzhuanyi-title-img" mode="widthFix"
        alt="" />
    </div>
    <div class="live-info-guzhuanyi-content-block">
      <div class="live-info-guzhuanyi-title-text global-color" :style="$tools.getColor()">{{meetingInfo?.meet_schedule?.name}}</div>
      <div class="live-info-guzhuanyi-date-block">
        <div class="live-info-guzhuanyi-date-title-text" :style="$tools.getBackgroundColor()">会议时间</div>
        <div class="live-info-guzhuanyi-date-content-block">
          <div class="live-info-guzhuanyi-date-content-left">
            <div class="live-info-guzhuanyi-date-year">
              <img :src="$tools.getImageContent('info.right-sj')" mode="widthFix"
                class="live-info-guzhuanyi-right-date-img" alt="" /><span>{{ this.$tools.getDate(this.meetingInfo.meet_info.start_time, "yyyy") }}</span>
            </div>
            <div class="live-info-guzhuanyi-date-month" :style="$tools.getColor()">
            {{ this.$tools.getDate(this.meetingInfo.meet_info.start_time, "MM/dd") }}
            </div>
          </div>
          <div class="live-info-guzhuanyi-date-content-middle" :style="$tools.getBackgroundColor()"></div>
          <div class="live-info-guzhuanyi-date-content-right">
            <div class="live-info-guzhuanyi-date-week-icon global-center">
              <img :src="$tools.getImageContent('info.date')" mode="widthFix" class="live-info-guzhuanyi-date-icon"
                alt="" />
              {{ this.weeks[this.$tools.getFormatTime(this.meetingInfo.meet_info.start_time).getDay()] }}
            </div>
            <div class="live-info-guzhuanyi-date-time global-center">
              <img :src="$tools.getImageContent('info.time')" mode="widthFix" class="live-info-guzhuanyi-date-icon"
                alt="" />
              
            {{ this.$tools.getDate(this.meetingInfo.meet_info.start_time, "hh/mm") }}~{{ this.$tools.getDate(this.meetingInfo.meet_info.end_time, "hh/mm") }}
            </div>
          </div>
        </div>
      </div>
      <div class="live-info-guzhuanyi-info-block global-center"  :style="$tools.getColor()">
        <img :src="$tools.getImageContent('info.left-arrow')" mode="widthFix" class="live-info-guzhuanyi-info-arraw"
          alt="" />
        <div class="live-info-guzhuanyi-theme-title">{{meetingInfo?.meet_schedule?.theme_name}}</div>
        <img :src="$tools.getImageContent('info.right-arrow')" mode="widthFix" class="live-info-guzhuanyi-info-arraw"
          alt="" />
      </div>
      <div class="live-info-guzhuanyi-theme">{{meetingInfo?.meet_schedule?.theme_content}}</div>
      <div class="live-info-guzhuanyi-info-block global-center"  :style="$tools.getColor()">
        <img :src="$tools.getImageContent('info.left-arrow')" mode="widthFix" class="live-info-guzhuanyi-info-arraw"
          alt="" />
        <div class="live-info-guzhuanyi-theme-title">{{meetingInfo?.meet_schedule?.info_name}}</div>
        <img :src="$tools.getImageContent('info.right-arrow')" mode="widthFix" class="live-info-guzhuanyi-info-arraw"
          alt="" />
      </div>
        <div class="live-info-guzhuanyi-theme">{{meetingInfo?.meet_schedule?.doctor_info}}</div>
      <div class="live-info-guzhuanyi-content-row live-info-guzhuanyi-table global-border" :style="$tools.getBorderColor()">
        <div class="live-info-guzhuanyi-table_title_tr" :style="$tools.getBackgroundColor()">
          <div class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table-td-title"
            :style="$tools.getImageBg('info.td_bg')">
            <!-- <img :src="$tools.getImageContent('info.time')" class="live-info-guzhuanyi-table-td-title-img" alt=""/> -->
            时间
          </div>
          <div class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table-td-title">
            内容
          </div>
          <div class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table-td-title">
            讲者
          </div>
        </div>
        <div class="live-info-guzhuanyi-table_content_tr live-info-guzhuanyi-table_content_tr"
          v-for="(item, key) in (meetingInfo?.meet_schedule?.timeline || [])" :key="key">
          <div
            class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table_td_content global-border global-color" :style="`${$tools.getBorderColor()}`"
            >
            {{ `${item.start_time}-${item.end_time}` }}
          </div>
          <div
            class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table_td_content global-border global-color" :style="`${$tools.getBorderColor()}`">
            {{ item.content }}
          </div>
          <div
            class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table_td_content global-border global-color" :style="`${$tools.getBorderColor()}`">
            {{ item.doctor }}
          </div>
        </div>
      </div>
      <div class="live-info-guzhuanyi-QRCode global-color" >
        <div class="live-info-guzhuanyi-QRCode-block global-border" :style="$tools.getBorderColor()">
          <img class="live-info-guzhuanyi-QRCode_img" :src="$tools.getImageContent('info.code')" alt="" />
        </div>
        <div class="live-info-guzhuanyi-prompt">{{meetingInfo?.meet_schedule?.prompt}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'live-info-guzhuanyi',
  props: {
    meetingInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      weeks: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
    }
  },

  mounted() {
  },
  methods: {
    getMeetTimeText() {
      if (!this.meetingInfo?.meet_info) return "暂无";
      let ymd = this.$tools.getDate(this.meetingInfo.meet_info.start_time, "yyyy年MM月dd日")
      let week = this.weeks[this.$tools.getFormatTime(this.meetingInfo.meet_info.start_time).getDay()];
      let start_time = this.$tools.getDate(this.meetingInfo.meet_info.start_time, "hh:mm")
      let end_time = this.$tools.getDate(this.meetingInfo.meet_info.end_time, "hh:mm")
      return `${ymd} ${week} ${start_time}-${end_time}`
    }
  }
}
</script>
<style lang="scss">
.live-info-guzhuanyi-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-info-guzhuanyi-title_banner {
  width: 100%;
}

.live-info-guzhuanyi-title-img {
  display: block;
  width: 100%;
  height: auto;
}

.live-info-guzhuanyi-content-block {
  width: 100%;
  flex: 1 0 0;
  background-repeat: no-repeat;
  padding: 0 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.live-info-guzhuanyi-title-text {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}

.live-info-guzhuanyi-date-block {
  width: 70%;
  // box-shadow: ;	
}

.live-info-guzhuanyi-date-title-text {
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #fff;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;

}

.live-info-guzhuanyi-date-content-block {
  display: flex;
  color: #000;
  margin-bottom: 22.5px;
  background: #fff;
  height: 60px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  padding:7.5px 0;
  box-shadow: 0px 1px 2px 1px rgba(242,103,74,.2);
}

.live-info-guzhuanyi-date-content-left {
  flex: 4 0 0;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end
}

.live-info-guzhuanyi-date-year {
  font-size: 13px;
  height:15px;
  line-height: 15px;
  display:flex;
  align-items:center;
}

.live-info-guzhuanyi-date-month {
  font-size: 24px;
  font-weight: 700;

}

.live-info-guzhuanyi-date-content-middle {
  width: 1px;
  height: 100%;
  margin: 0 7.5px;
}

.live-info-guzhuanyi-date-content-right {
  flex: 6 0 0;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.live-info-guzhuanyi-right-date-img {
  width: 11.25px;
  // height:10.36px;
  margin-right: 3.75px;

}

.live-info-guzhuanyi-date-week-icon {}

.live-info-guzhuanyi-date-time {}

.live-info-guzhuanyi-date-icon{
  width:15px;
  margin-right:3.75px;;
}
.live-info-guzhuanyi-info-block {
  font-weight: 700;
  font-size:14px;
}

.live-info-guzhuanyi-info-arraw {
  width:18.75px;
}

.live-info-guzhuanyi-theme-title {
  margin:0 7.5px;
  font-size:14px;
}


.live-info-guzhuanyi-info-block {}


.live-info-guzhuanyi-theme {
  color:#000;
  margin:7.5px 0 18.75px;
  font-size:14px;
}


.live-info-guzhuanyi-content-row {
  margin-top: 13.125px;
  width: 100%;
}

.content_lable_key {
  font-weight: 600;
}

.content_doctor_row {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  word-break: break-all;
}

.content_doctor_row_name {
  margin-right: 30px;
}

.live-info-guzhuanyi-table {
  border:none;
  margin-bottom:15px;
}

.live-info-guzhuanyi-table_title_tr {
  display: flex;
  width: 100%;
  height: 37.5px;
  border-top-left-radius: 11.25px;;
  border-top-right-radius: 11.25px;;
}

.live-info-guzhuanyi-table_content_tr {
  display: flex;
  width: 100%;
  align-items: center;
}

.live-info-guzhuanyi-table_content_tr:nth-child(2n) {
  background: rgb(240,240,240);
}

.live-info-guzhuanyi-table_content_tr:nth-child(2n-1) .live-info-guzhuanyi-table_td_content,
.live-info-guzhuanyi-table_content_tr .live-info-guzhuanyi-table_td_content:last-child {
  border-right: none;
}

.live-info-guzhuanyi-table_td_content {
  border:none;
}

.live-info-guzhuanyi-table_td {
  min-height: 37.5px;
  flex: 1 0 0;
  padding: 0 1.875px;
  text-align: center;
  color:#000;
  font-size:14px;
}

.live-info-guzhuanyi-table-td-title {
  background-repeat: no-repeat;
  // color: #fff;
  font-weight: 600;
  padding: 13.5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
}

.live-info-guzhuanyi-table-td-title-img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.live-info-guzhuanyi-QRCode {
  width: 100%;
  height: 210px;
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.live-info-guzhuanyi-QRCode_img {
  width: 130px;
  height: 130px;
}
.live-info-guzhuanyi-QRCode-block{
  flex:150px 0 0;
  height:150px;
  border-radius:5px;
  margin-right:10px;
  padding:10px;
  border-width: 1px;
  border-style: solid

}
.live-info-guzhuanyi-prompt{
  font-size: 12px;
    color: #000;
    line-height: 24px;
}
</style>