<template>
  <div class="live-chat-container">
    <div class="live-chat-content">
      <div
        class="live-chat-li"
        :style="{ color: getColor(item) }"
        v-for="(item, index) in chatList"
        :key="`${item.name}${index}`"
      >
        <div class="live-chat-name">{{ item.name }}：</div>
        <div class="live-chat-record">
          <div v-if="item.content.includes('^^')">
            <span
              v-for="(content,cIndex) in item.content.split('^^')"
              :key="`${content}${cIndex}`"
              :class="{ 'live-chat-font': content.includes('##') }"
              >{{ content.replace("##", "") }}</span
            >
          </div>
          <span v-else>{{ item.content }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// ## 加粗
// ^^ 分隔符
import CommonScroll from "@/components/unit/CommonScroll";
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "live-chat",
  props: {
    meetingInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    CommonScroll,
    CustomButton,
  },
  data: () => {
    return {
      message: "",
      msg:{
        name: "系统",
        content: "本互动不提供涉及药品或诊疗相关的任何专业咨询，如对于药品或诊疗相关的任何问题须咨询专业医生。",
        color:"gray"
      }
    };
  },
  computed: {
    chatList() {
      return this.getChatResult();
    },
  },
  created() {},
  methods: {
    getChatResult(){
      let chatList = this.meetingInfo.chat_info || [];
      chatList = chatList.filter((item) => item.content_type != 1);
      let commonList = this.generatCommonList()
      let result = []
      for(let chat of chatList){
        let chats = [chat]
        if(!(chat.name == "系统" || commonList.includes(chat.content))) chats.push(this.msg)
        result = result.concat(chats)
      }
      return result
    },
    
    generatCommonList(){
      let list = ["教授好","老师好","老师，早上好","教授，早上好","教授好","老师好","老师，下午好","教授，下午好","教授好","老师好","老师，晚上好","教授，晚上好"]
      let result = []
      list.forEach(val=>{
        result.push(`${this.meetingInfo?.meet_info?.doctor_name || ""}${val}`)
      })
      return (this.meetingInfo.word_commcon || []).concat(result)
    },
    getColor(item) {
      if (item.color) return item.color;
      if (item.openid == this.meetingInfo.openid) return this.$config.color;
      return this.$tools.getOpenid() == item.openid ? this.$config.color : "#fff";
    },
    sendQuestion() {
      this.talk(this.message);
      this.message = "";
    },
    isSelf(item) {
      return this.info.openid === item.openid;
    },
    initEnter() {
      document.onkeydown = () => {
        var key = window.event.keyCode;
        if (key == 13) {
          this.talk();
        }
      };
    },
    focusInput() {
      this.showFastMessage = false;
      this.initEnter();
    },
    talk(content = "") {
      let data = this.$tools.getChatContent(
        content,
        this.meetingInfo.show_name
      );
      this.$emit("sendMessage", data);
    },
  },
};
</script>
<style lang="scss">
.live-chat-container {
  padding: 15px 0;
}
.live-chat-li {
  display: flex;
  line-height: 24px;
  padding: 0 15px;
  font-size:14px;
}
.live-chat-name {
  // flex:90px 0 0;
  text-align: text;
  white-space: nowrap;
}
.live-chat-record {
  word-break: break-all;
}
.live-chat-button {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.live-chat-button .customButton_container {
  height: 33px !important;
}
.live-chat-button .global_text_input {
  padding: 0;
}
.live-chat-font {
  font-weight: 700;
}
</style>