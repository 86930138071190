// 通用操作方法
export default {
  data() {
    return {
      isNextStep: false,
      isNextPage: false,
      stepTime: 0,
      stepSpaceTime: 500
    }
  },
  methods: {
    goToNextPage(status) {
      if (this.stepTime > Date.now()) return;
      if (this.meetingInfo?.meet_info?.status == 0 && status) return;
      this.stepTime = Date.now() + this.stepSpaceTime
      this.isNextPage = true
      let doctorIndex = this.meetingInfo.doctor_index + 1
      let maxIndex = this.meetingInfo.class_info.content.length + 1
      let pageIndex = doctorIndex >= maxIndex ? maxIndex : doctorIndex
      this.meetingInfo.guest_index = pageIndex
      this.meetingInfo.doctor_index = pageIndex
      if(this.isMoreThanEndPage()){
        this.changeBanner("chat")
      }
      if (status) this.turnPage()
    },
    goToPrepPage(status) {
      if (this.stepTime > Date.now()) return;
      if (this.meetingInfo?.meet_info?.status == 0 && status) return;
      this.stepTime = Date.now() + this.stepSpaceTime
      this.isNextPage = true
      let doctorIndex = this.meetingInfo.doctor_index - 1
      let pageIndex = doctorIndex > 0 ? doctorIndex : 1
      if (doctorIndex == 1 && this.meetingInfo.doctor_index == 1) return;
      this.meetingInfo.guest_index = pageIndex
      this.meetingInfo.doctor_index = pageIndex
      this.changeBanner("text")
      if (status) this.turnPage()
    },
    goToNextStep() {
      if (this.stepTime > Date.now()) return;
      this.stepTime = Date.now() + this.stepSpaceTime
      this.isNextStep = true
      if(this.isCurrStep("courseware")){
        this.meetingInfo.guest_index += 1
        this.meetingInfo.doctor_index += 1
        this.turnPage()
        this.changeBanner("chat")
      }
      this.sendMessage({
        type: "control",
        data: {
          is_test: this.meetingInfo.is_test,
          phase: this.meetingInfo.phase + 1,
        },
      })
    },
    goToPrepStep() {
      if (this.stepTime > Date.now()) return;
      this.stepTime = Date.now() + this.stepSpaceTime
      this.isNextStep = true
      this.sendMessage({
        type: "control",
        control: {
          is_test: this.meetingInfo.is_test,
          phase: this.meetingInfo.phase - 1,
        }
      })
    },
    goToDiscuss() {
      if (this.stepTime > Date.now()) return;
      if (this.isCurrStep("courseware") && !this.meetingInfo.is_test && this.meetingInfo.local_tm < this.meetingInfo.class_time) {
        this.initDiscussTip()
        return;
      }
      this.goToNextStep()
    },
    goToEndStep() {
      if (this.stepTime > Date.now()) return;
      if (this.meetingInfo.isPushing) {
        this.stopStream(true)
      }
      if (this.isCurrStep("discuss") && !this.meetingInfo.is_test) {
        this.initMeetEndTip()
        return;
      }
      this.goToNextStep()
    },
    turnPage() {
      this.sendMessage({
        type: "flip",
        data: {
          doctor_index: this.meetingInfo.doctor_index,
          guest_index: this.meetingInfo.guest_index
        }
      })
    },

  }
}
