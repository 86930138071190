export default {
  data(){
    return {
      steps:{
        start:0,
        courseware:1,
        discuss:2,
        end:3,
      },
    }
  },
  methods:{
    isEndPage(){
      if(!this.meetingInfo.class_info.content) return false
      return this.meetingInfo.doctor_index == this.meetingInfo.class_info.content.length
    },
    isMoreThanEndPage(){
      if(!this.meetingInfo.class_info.content) return false
      return this.meetingInfo.doctor_index > this.meetingInfo.class_info.content.length
    },
    isCurrStep(step){
      return this.meetingInfo.phase == this.steps[step]
    },
    isAllowOpera(){
      return [1,2].includes(this.meetingInfo.phase)
    },
    async isSign(callback){
      let signInfo = this.$store.state.common.signInfo || {};
      console.log(signInfo)
      if(signInfo.openid) {
        callback()
        return;
      }
      try{
        this.$store.dispatch("setItem",{signInfo:{name:"xxx",openid:"o5Y9a6xx6g2hW3XiFJ9fVkdhrdDc1"}})
        callback()
      // let url = this.$tools.getURL(this.$urls.client.sign,this.$tools.getSystemInfo())
      // try{
      //   let response = await this.$axios.get(url,{},{timeout:3000})
      //   if(response.data && response.data.openid){
      //     this.$store.dispatch("setItem",{signInfo:response.data})
      //     callback()
      //   }else{
      //     uni.navigateTo({
      //       url: this.$tools.getURL("/pages/user/sign",{meeting_number:this.meetingInfo.meeting_number}),
      //     });
      //     this.$router.push({
      //       path:'/sign',
      //       query:{meeting_number:this.meetingInfo.meeting_number}
      //     })
      //   }
      }catch(err){
        console.log(err)
        callback(1)
      }
    },
    clearSignInfo(){
      this.$store.dispatch("setItem",{signInfo:null})
    },
    setThePageTitle(){
      let prefix = this.meetingInfo.is_test == 0 ? "" : "测试会议"
      document.title = `${prefix}${this.meetingInfo.meet_info.name}`
    },
  }
}