// 定时器事件
export default {
  data(){
    return {
      liveTimer:{
        instance:null,
        startTime:"",
        speed:1.05,
        sleep:1000,
        index:0,
        currIndex:0
      },
      execPlayStream:false,
      playerLoading:false,
      isStreanPushing:false,
    }
  },
  methods:{
    initTimer(currIndex) {
      console.log(currIndex,this.liveTimer.currIndex)
      if (this.liveTimer.instance) {
        clearTimeout(this.liveTimer.instance);
        this.liveTimer.instance = null;
      }
      this.dealWithEvent()
      this.liveTimer.instance = setTimeout(()=>{
        if(this.liveTimer.currIndex != currIndex ) return;
        this.initTimer(currIndex)
      },this.liveTimer.sleep*this.liveTimer.speed)
    },
    runTimer(){
      this.changeIndex()
      setTimeout(()=>{
        this.initTimer(this.liveTimer.currIndex)
      },this.liveTimer.sleep + 100)
    },
    changeIndex(){
      this.liveTimer.currIndex = Math.floor(Math.random() * 10000)
    },
    dealWithEvent(){
      this.loadPlayInfo()
    },
    // active：活跃，
    // inactive：非活跃，
    // forbid：禁播。
    loadPlayInfo(){
      if(this.playerLoading || this.meetingInfo.meet_info.status != 1 || this.execPlayStream) return;
      let url = this.$tools.getURL(this.$urls.live.verify,{id:this.meetingInfo.meet_info.id})
      // let url = this.$tools.getURL("https://bzjt.medflying.com/v2/ui/live/live_info",{id:this.meetingInfo.meet_info.id})
      this.playerLoading = true
      this.$axios.get(url,{},{ timeout: 5000 }).then(res=>{
        if(res.data.StreamState == "active"){
          this.execPlayStream = true
          setTimeout(()=>{
            this.execPlayStream = false;
            this.isStreanPushing = true;
            this.startPlayStream()
          },2000)
        }else{
          this.isStreanPushing = false;
          this.stopPlayStream()
        }
        setTimeout(()=>{ this.playerLoading = false},300)
      }).catch(err=>{
        this.stopPlayStream()
        this.isStreanPushing = false;
        setTimeout(()=>{ this.playerLoading = false},300)
      })
    },
  }
}