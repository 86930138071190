<template>
  <div class="v2-live-courseware-block">
    <PPTPlayer ref="player" :data="getContent()" :isImage="false" :isHaveButton="false">
      <template v-slot="{ cItems, cIndex }">
        <div class="v2-live-course-item global-div-center">
          <CustomImage :src="cItems.content" />
        </div>
      </template>
    </PPTPlayer>
    <div class="global_container_mask"></div>
  </div>
</template>
<script>
import PPTPlayer from "@/components/player/PPTPlayer"
import CustomImage from '@/components/unit/CustomImage'
export default {
  name: "player",
  components: {
    PPTPlayer,
    CustomImage,
  },
  props: {
    meetingInfo: Object,
    default: () => {
      return {};
    },
  },
  methods: {
    getContent() {
      return this.meetingInfo.class_info?.content || []
    },
    turnPage(meetingInfo){
      this.$refs.player.turnPage(meetingInfo)
    },
  }
};
</script>
<style>
.v2-live-courseware-ul,
.v2-live-course-box {
  height: auto;
  width: 100%;
}

.v2-live-courseware-block {
  position: relative;
  height: auto;
  width: 100%;
  position: absolute;
  left:0;
  top:0;
}

.v2-live-course-item {
  height: 100%;
  width: 100%;
  display: flex;
}

.v2-live-course-item image {
  width: 100%;
  height: 100%;
}
</style>