
import websockets from "@/common/websockets";
/**websocket区域 */
export default  {
  data(){
    return {
      events: {
        login: "dealWithLogin",
        control: "dealWithControl",
        online: "dealWithOnline",
        offline: "dealWithOffline",
        chat: "dealWithChat",
        video: "dealWithVideo",
        error: "dealWithError",
        flip: "dealWithFlip",
        local_tm: "dealWithLocalTm",
        network: "dealWithNetwork",
      }
    }
  },
  methods:{
    async initWebsocket() {
      websockets.changeIndex()
      websockets.initWebsocket(this.meetingInfo.id, this.dealWithCallback, {
        role: this.meetingInfo.role,
        info:this.$tools.getSystemInfo()
      });
    },  
    sendChat(data) {
      let msg = {
        type: "chat",
        data,
      };
      this.sendMessage(msg);
    },
    sendLog(type,content) {
      content.createTime = this.$tools.getDate(null,"yyyy-MM-dd hh:mm:ss")
      let msg = {
        type: "log_records",
        data:{
          type,
          content,
          meeting_id:this.meetingInfo.meet_info.id
        }
      };
      this.sendMessage(msg);
    },
    sendMessage(data) {
      websockets.sendMessage(data);
    },
    
    sendSystemMessage(text, color = this.$config.color) {
      if (!text) return;
      let msg = {
        name: "系统",
        content: text,
        color,
        create_time: this.$tools.getTime("", "yyyy-MM-dd hh:mm:ss"),
      };
      this.meetingInfo.chat_info.push(msg);
      this.initScrollBottom()
    },
    
    sendSystemTips(){
      switch(this.meetingInfo.phase){
        case 1:
          let msg = this.$config.popups.v2.phase[this.meetingInfo.phase]
          msg = msg.replace("{doctor}",this.meetingInfo.meet_info.doctor_name).replace("{courseware}",this.meetingInfo.class_info.title)
          this.sendSystemMessage(msg)
          break;
        case 2:;
        case 3:
          this.sendSystemMessage(this.$config.popups.v2.phase[this.meetingInfo.phase])
          break;
      }
    },
    dealWithCallback(data) {
      this[this.events[data.type]] && this[this.events[data.type]](data.data);
    },
    async dealWithLogin(data) {
      console.log(data)
      this.meetingInfo = {
        ...this.meetingInfo,
        ...data
      }
      this.meetingInfo.doctor_index = this.meetingInfo.doctor_index || 1
      this.meetingInfo.guest_index = this.meetingInfo.guest_index || 1
      this.$nextTick(()=>{
        if(this.meetingInfo.tab == "chat") this.initScrollBottom()
      })
      this.turnPlayerPage()
      this.setThePageTitle()
      console.log(this.closeWelcomeTip,this.meetingInfo.meet_info.status)
      if(!this.closeWelcomeTip && this.meetingInfo.meet_info.status < 2){
        this.initWelcome()
      }
    },
    dealWithControl(data) {
      this.meetingInfo.phase = data.phase
      this.meetingInfo.is_test = data.is_test
      this.isNextStep = false
      this.sendSystemTips()
      if(data.phase == 1){
        this.setThePageTitle()
        this.meetingInfo.meet_info.status = 1
      }
      if(data.phase==3){
        this.meetingInfo.meet_info.status = 2
        // window.location.reload()
        // uni.navigateBack()
      }
      if(data.phase==0){
        this.meetingInfo.meet_info.status = 0
        this.changeIndex()
        window.location.reload()
        // uni.navigateBack()
      }
      
      if(this.isCurrStep("end")){
        this.changeIndex()
        // window.location.reload()
        // uni.navigateBack()
      }
    },
    dealWithOnline(data) {
      let text = "",
        color = undefined;
      if (data.openid == this.meetingInfo.openid) {
        color = this.$config.color;
        text = `${data.name}${this.$config.popups.academic}`;
      } else text = data.name;
      if (this.meetingInfo.phase >= 0 && data.openid == this.meetingInfo.openid && this.meetingInfo.meet_info.status < 2) {
        this.sendSystemMessage(`${text}${this.$config.popups.infloor}`, color);
      }
      this.meetingInfo.count = data.online_count < 2 ? 2 : data.online_count;
    },
    dealWithOffline(data) {
      this.meetingInfo.count = data.online_count < 2 ? 2 : data.online_count;
    },
    dealWithChat(data) {
      console.log(data)
      this.meetingInfo.chat_info.push(data);
      if (this.meetingInfo.tab == 'chat') this.initScrollBottom();
    },
    dealWithError(data) {
      this.$tips.error({ title: data.msg });
    },
    dealWithLocalTm(data) {
      this.meetingInfo.local_tm = data.local_tm
      // console.log(this.meetingInfo.local_tm)
    },
    dealWithFlip(data) {
      this.isNextPage = false
      this.meetingInfo.guest_index = data.guest_index
      this.meetingInfo.doctor_index = data.doctor_index
      this.turnPlayerPage()
    },
    turnPlayerPage(){
      this.$nextTick(()=>{
        this.$refs.player && this.$refs.player.turnPage(this.meetingInfo)
      })
    },
    dealWithNetwork(data) {
      if(!data.status){
        this.popupKeys["guestNetwork"] = ""
        this.initGuestNetworkTip()
      }else{
        this.popupKeys["guestNetwork"] = ""
        this.showMeetingTip = false;
      }
    },
    closeWebsocket(){
      websockets.close()
    },
    
  }
}
/**websocket区域 */