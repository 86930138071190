<template>
  <div class='live-text-container'>
    {{ getPrompt() }}
  </div>
</template>
<script>
// ## 加粗
// ^^ 分隔符
import CustomButton from '@/components/unit/CustomButton'
import get from '@/components/page/live/vendor/get'
export default {
  name:'live-chat',
  mixins:[get],
  props:{
      meetingInfo:{
          type:Object,
          default:()=>{
              return { }
          }
      },
  },
  components:{
    CustomButton,
  },
  data:()=>{
      return {
        message:""
      }
  },
  created(){
  },
  methods:{
  }
}
</script>
<style lang="scss">
.live-text-container{
  padding:15px 30px;
  line-height: 24px;
}
</style>